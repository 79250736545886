import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import React, {Component} from 'react';
import RestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {bool, func, number, object, oneOf, string} from 'prop-types';

import AuditLog from 'admin-ng/components/common/audit-log';
import Card from 'admin-ng/components/common/card';
import DataState from 'admin-ng/components/common/data-state';
import DatePicker from 'admin-ng/components/common/date-picker';
import QuotaUsage from 'admin-ng/components/common/subscription-details/quota-usage';
import {DATETIME_FORMAT, DATE_FORMAT} from 'admin-ng/constants';
import {checkMomentObject} from 'admin-ng/components/utils';
import {formatLargeNumber, localizeNumber} from 'admin-ng/util/formatting';
import {getPrimaryText} from 'admin-ng/components/common/subscription-details/quota/intervals';
import {getPrimaryText as getPrimaryText2} from 'admin-ng/components/common/subscription-details/tile-quota/intervals';
import {getQuotaStylePrimaryText} from 'admin-ng/components/common/subscription-details/quota/quota-styles';
import {infiniteQuotaCap} from '../quota/reducer';
import {resetBasemapTileQuota} from './actions';

export const shouldDateBeDisabled = (day, accessPeriodFrom, accessPeriodTo) => {
  const date = moment.utc(day).startOf('day');
  const minDate = moment
    .utc(accessPeriodFrom)
    .subtract(1, 'day')
    .startOf('day');
  const maxDate = accessPeriodTo
    ? moment.utc(accessPeriodTo).add(1, 'day').endOf('day')
    : null;
  return date.isBefore(minDate) || (maxDate && date.isAfter(maxDate));
};

const resetTargetLabels = {
  basemapTiles: 'basemap tiles',
};

export default class OrgSubscriptionsInstance extends Component {
  static propTypes = {
    accessPeriodFrom: checkMomentObject,
    accessPeriodTo: checkMomentObject,
    auth: object.isRequired,
    basemapTileQuota: object,
    dispatch: func.isRequired,
    downloadCoverage: func.isRequired,
    entity: oneOf(['plan', 'organization']).isRequired,
    entityId: number,
    entityName: string,
    history: object.isRequired,
    id: number.isRequired,
    isOwnOrgAdmin: bool.isRequired,
    isPlanetAdmin: bool.isRequired,
    onMount: func.isRequired,
    organizationId: number,
    overage: bool,
    quotaAmount: number,
    quotaEnabled: bool.isRequired,
    quotaInterval: string,
    quotaStyle: string,
    quotaUsage: number,
    policyAvailable: bool,
    reference: string.isRequired,
    sceneTileQuota: object,
    state: string,
    status: string,
    subscriptionId: string,
    updated_at: string,
  };

  static defaultProps = {
    accessPeriodFrom: null,
    accessPeriodTo: null,
    entityId: null,
    entityName: null,
    organizationId: null,
    quotaInterval: null,
    quotaStyle: null,
    quotaUsage: null,
    policyAvailable: false,
    state: null,
  };

  state = {
    dateFrom: null,
    dateTo: null,
    dateError: null,
    alertOpen: false,
    log: 'subscriptions',
    resetTarget: null,
  };

  componentDidMount() {
    this.props.onMount(this.props.auth.user.claims);
  }

  componentWillReceiveProps({id, onMount}) {
    const {id: oldId} = this.props;
    if (id !== oldId) {
      onMount(this.props.auth.user.claims);
    }
  }

  openAlert = target => this.setState({alertOpen: true, resetTarget: target});

  closeAlert = () => this.setState({alertOpen: false});

  handleUsedQuotaReset = () => {
    this.closeAlert();
    switch (this.state.resetTarget) {
      case 'basemapTiles':
        this.props.dispatch(
          resetBasemapTileQuota(
            this.props.subscriptionId,
            this.props.auth.user.claims
          )
        );

        break;
      default:
        throw new Error();
    }
  };

  _handleEdit = () => {
    const {id, organizationId} = this.props;
    this.props.history.push(
      `/organizations/${organizationId}/plans/${id}/edit`
    );
  };

  _disabledDays = day => {
    const {accessPeriodFrom, accessPeriodTo} = this.props;
    return shouldDateBeDisabled(day, accessPeriodFrom, accessPeriodTo);
  };

  _handleDateChange = (dateFrom, dateTo) => {
    if (dateFrom && dateTo) {
      if (dateFrom > dateTo) {
        this.setState({dateError: 'End date must be after beginning date'});
      } else {
        this.setState({dateError: null});
        const {downloadCoverage, id} = this.props;
        const pyDateFrom = moment.utc(dateFrom).startOf('day').format();
        const pyDateTo = moment.utc(dateTo).endOf('day').format();
        downloadCoverage(
          id,
          pyDateFrom,
          pyDateTo,
          `${id}.geojson`,
          'application/vnd.geo+json'
        );
      }
    }
  };

  _handleFromChange = (event, date = null) => {
    if (!this._disabledDays(date)) {
      this.setState({dateFrom: date});
      this._handleDateChange(date, this.state.dateTo);
    }
  };

  _handleToChange = (event, date = null) => {
    if (!this._disabledDays(date)) {
      this.setState({dateTo: date});
      this._handleDateChange(this.state.dateFrom, date);
    }
  };

  _renderItems = items => {
    return (
      <ul className="pl-org-details pl-details-list clearfix">
        {items.map((it, i) => {
          const clazz = ['item'];
          const qaId = it.length > 2 ? it[2] : '';
          i % 2 === 1 && clazz.push('item-right');
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li className={clazz.join(' ')} key={i}>
              <div className="pl-details-list--title">{it[0]}</div>
              <div className="pl-details-list--value" data-qa-id={qaId}>
                {it[1] || '-'}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  _renderSection(title, items, resetTarget) {
    if (items.length > 0) {
      return (
        <div style={{paddingBottom: '3em', position: 'relative'}}>
          {title && <h3 className="pl-form--section-title">{title}</h3>}
          <div className="pl-form--section-menu">
            {this.props.isPlanetAdmin &&
              resetTarget &&
              resetTarget !== 'sceneDownloads' && (
                <Button
                  startIcon={<RestoreIcon />}
                  onClick={() => this.openAlert(resetTarget)}
                  sx={{marginLeft: 12}}
                  variant="contained"
                >
                  Reset used quota
                </Button>
              )}
          </div>
          {this._renderItems(items)}
        </div>
      );
    }
  }

  _renderExport(dateFrom, dateTo, dateError) {
    return (
      <div className="pl-flex-wrapper">
        <DatePicker
          dateFormatConfirmation="D MMMM Y -1:-1"
          disabledDays={this._disabledDays}
          placeholder="From"
          onChange={this._handleFromChange}
          onClear={this._handleFromChange}
          qaId="pl-details-list-download-from"
          value={dateFrom}
        />

        <DatePicker
          dateFormatConfirmation="D MMMM Y 22:58"
          disabledDays={this._disabledDays}
          error={!!dateError}
          helperText={dateError}
          placeholder="To"
          onChange={this._handleToChange}
          onClear={this._handleToChange}
          qaId="pl-details-list-download-to"
          value={dateTo}
        />
      </div>
    );
  }

  _quotaItems(quota) {
    const items = [];

    if (quota && quota.limit > 0) {
      items.push([
        'Updated',
        quota.current_updated
          ? moment.utc(quota.current_updated).format(DATETIME_FORMAT)
          : '-',
      ]);
      items.push([
        'Reset',
        quota.reset ? moment.utc(quota.reset).format(DATE_FORMAT) : '-',
      ]);
      items.push([
        'Limit',
        quota.limit === infiniteQuotaCap ? 'Unlimited' : quota.limit,
      ]);
      items.push(['Interval', getPrimaryText2(quota.refresh)]);
      items.push(['Used', quota.current]);
      items.push([
        'Quota',
        quota.limit === infiniteQuotaCap ? (
          'Unlimited'
        ) : (
          <QuotaUsage key="q" total={quota.limit} usage={quota.current} />
        ),
      ]);
      items.push(['Overage', quota.overage ? 'Enabled' : 'Disabled']);
    }
    return items;
  }

  _renderLog(current, name, id) {
    // by using display, we maintain the loaded log state if switching between
    return (
      <div style={{display: current === name ? '' : 'none'}}>
        <AuditLog modelId={id} modelName={name} />
      </div>
    );
  }

  render() {
    const {
      accessPeriodFrom,
      accessPeriodTo,
      basemapTileQuota,
      entity,
      entityId,
      entityName,
      id,
      isOwnOrgAdmin,
      isPlanetAdmin,
      quotaAmount,
      quotaEnabled,
      quotaInterval,
      quotaStyle,
      quotaUsage,
      policyAvailable,
      reference,
      sceneTileQuota,
      state,
      status,
      updated_at,
    } = this.props;
    const {dateFrom, dateTo, dateError, log} = this.state;
    const formattedAccessPeriodFrom = accessPeriodFrom
      ? moment.utc(accessPeriodFrom).format(DATETIME_FORMAT) + ' UTC'
      : 'Invalid date';
    const formattedAccessPeriodTo = accessPeriodTo
      ? moment.utc(accessPeriodTo).format(DATETIME_FORMAT) + ' UTC'
      : 'No end date';

    const summaryItems = [
      ['ID', id, 'pl-details-list-name'],
      [
        'State',
        state && (
          <DataState
            key={`${id}-state`}
            label={state === 'inactive_plan' ? 'Deleted Package' : null}
            state={state}
          />
        ),
        'pl-details-list-state',
      ],
      [
        'Access Period',
        `${formattedAccessPeriodFrom} – ${formattedAccessPeriodTo}`,
        'pl-details-list-program',
      ],
      [
        'Access Policies',
        <DataState
          key={`${id}-ap`}
          label={policyAvailable === false ? 'Not Available' : 'Available'}
          state={policyAvailable === false ? 'disabled' : 'active'}
        />,
        'pl-details-list-state',
      ],
    ];

    const sceneQuotaItems = [];
    if (quotaEnabled) {
      sceneQuotaItems.push([
        'Limit',
        <div key="limit" title={`${localizeNumber(quotaAmount)} km²`}>
          {quotaAmount === infiniteQuotaCap
            ? 'Unlimited'
            : formatLargeNumber(quotaAmount) + ' km²'}
        </div>,
        'pl-details-list-quota',
      ]);
      sceneQuotaItems.push([
        'Period',
        getPrimaryText(quotaInterval),
        'pl-details-list-quota-period',
      ]);
      sceneQuotaItems.push([
        'Used',
        status ? 'Error fetching used quota' : Math.round(quotaUsage),
        'pl-details-list-quota-used',
      ]);
      sceneQuotaItems.push([
        'Quota',
        quotaAmount === infiniteQuotaCap ? (
          'Unlimited'
        ) : (
          <QuotaUsage key="q" total={quotaAmount} usage={quotaUsage || 0} />
        ),

        'pl-details-list-quota-used',
      ]);
      if (quotaStyle === 'coverage') {
        sceneQuotaItems.push([
          'Export Geojson',
          this._renderExport(dateFrom, dateTo, dateError),
          'pl-details-list-download',
        ]);
      }
      sceneQuotaItems.push([
        'Updated',
        updated_at ? moment.utc(updated_at).format(DATETIME_FORMAT) : '-',
      ]);
    }

    const basemapTileItems = this._quotaItems(basemapTileQuota);
    const sceneTileItems = this._quotaItems(sceneTileQuota);

    if (isPlanetAdmin) {
      summaryItems.push(['Opportunity ID', reference]);
      if (quotaEnabled) {
        sceneQuotaItems.push([
          'Overage',
          this.props.overage ? 'Enabled' : 'Disabled',
        ]);
        sceneQuotaItems.push([
          'Download Plan',
          getQuotaStylePrimaryText(quotaStyle),
        ]);
      }
    }

    return (
      <div>
        <Card style={{marginBottom: '25px'}}>
          <div
            className="pl-form--section-header"
            style={{position: 'relative'}}
          >
            <h3 className="pl-form--section-title">
              {entityName ? 'Viewing plan for' : 'View plan'}
              {entityName && ( // TODO: figure out entity
                <span>
                  {` ${entity === 'plan' ? 'package' : entity} `}“
                  <Link
                    to={`/${entity === 'plan' ? 'package' : entity}s/${entityId}`}
                  >
                    {entityName}
                  </Link>
                  ”
                </span>
              )}
            </h3>

            {(isPlanetAdmin || !isOwnOrgAdmin) && (
              <div className="pl-form--section-menu">
                <Button
                  color="primary"
                  data-qa-id="pl-details-list-edit-subscription"
                  startIcon={<EditIcon />}
                  onClick={this._handleEdit}
                  variant="outlined"
                >
                  Edit
                </Button>
              </div>
            )}
          </div>

          {this._renderSection(null, summaryItems)}
          {this._renderSection(
            'Scene Quota',
            sceneQuotaItems,
            'sceneDownloads'
          )}
          {this._renderSection(
            'Basemap Tile Quota',
            basemapTileItems,
            'basemapTiles'
          )}
          {this._renderSection('Scene Tile Quota', sceneTileItems)}

          {this.state.alertOpen && (
            <Dialog
              maxWidth="sm"
              onClose={this.closeAlert}
              open={this.state.alertOpen}
            >
              <DialogTitle>Warning</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Continuing will reset the used
                  <span> {resetTargetLabels[this.state.resetTarget]} </span>
                  for this plan.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button color="inherit" onClick={this.closeAlert}>
                  Cancel
                </Button>

                <Button
                  onClick={this.handleUsedQuotaReset}
                  color="primary"
                  variant="contained"
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Card>

        <Button
          color={log === 'subscriptions' ? 'primary' : 'inherit'}
          onClick={() => this.setState({log: 'subscriptions'})}
          sx={{paddingX: 2}}
        >
          Audit plan
        </Button>

        <Button
          color={log === 'basemap-tile-quota' ? 'primary' : 'inherit'}
          onClick={() => this.setState({log: 'basemap-tile-quota'})}
          sx={{paddingX: 2}}
        >
          Audit Basemap Tile Quota
        </Button>

        <Button
          color={log === 'scene-tile-quota' ? 'primary' : 'inherit'}
          onClick={() => this.setState({log: 'scene-tile-quota'})}
          sx={{paddingX: 2}}
        >
          Audit Scene Tile Quota
        </Button>

        {this._renderLog(log, 'subscriptions', id)}
        {this._renderLog(log, 'basemap-tile-quota', id)}
        {this._renderLog(log, 'scene-tile-quota', id)}
      </div>
    );
  }
}
