import moment from 'moment';
import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import Instance from './instance';
import {ROLES} from 'admin-ng/constants';
import {
  basemapTileQuotaActions,
  sceneTileQuotaActions,
} from '../tile-quota/actions';
import {downloadCoverage} from 'admin-ng/api/subscriptions';
import {fetch, reset} from './actions';
import {reset as quotaReset} from 'admin-ng/components/common/subscription-details/quota/actions';

const mapStateToProps = (
  {
    subscriptions: {
      quota: {
        ui: {
          isEnabled: quotaEnabled,
          amount: quotaAmount,
          interval: quotaInterval,
          style: quotaStyle,
          overage,
        },
        quotaUsage,
        policyAvailable,
        updated_at,
        status,
      },
      accessPeriod: {
        ui: {activeFrom, activeTo},
      },
      instance: {
        basemapQuadQuota,
        basemapTileQuota,
        organizationId,
        organizationName,
        planId,
        planName,
        sceneTileQuota,
        reference,
        state,
      },
    },
  },
  {
    match: {
      path,
      params: {orgId, subscriptionId},
    },
    auth: {
      user: {
        claims: {role_level: roleLevel, organization_id: userOrganizationId},
      },
    },
  }
) => {
  const fromPlansRoute = path.indexOf('/plans') === 0;
  const entity = fromPlansRoute ? 'organization' : 'plan'; // TODO: Update
  const entityId = parseInt(fromPlansRoute ? organizationId : planId);
  const entityName = fromPlansRoute ? organizationName : planName;
  return {
    accessPeriodFrom: activeFrom ? moment.utc(activeFrom) : activeFrom,
    accessPeriodTo: activeTo ? moment.utc(activeTo) : activeTo,
    basemapQuadQuota,
    basemapTileQuota,
    entity,
    entityId,
    entityName,
    id: parseInt(subscriptionId),
    isOwnOrgAdmin:
      roleLevel === ROLES.orgAdmin && userOrganizationId === parseInt(orgId),
    isPlanetAdmin: roleLevel >= ROLES.planetAdmin,
    organizationId,
    overage,
    sceneTileQuota,
    subscriptionId,
    quotaAmount,
    quotaEnabled,
    quotaInterval,
    quotaStyle,
    quotaUsage,
    policyAvailable,
    updated_at,
    reference,
    state,
    status,
  };
};
const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: {subscriptionId},
    },
  }
) => ({
  dispatch,
  downloadCoverage,
  onMount: claims => {
    dispatch(reset());
    dispatch(basemapTileQuotaActions.reset());
    dispatch(sceneTileQuotaActions.reset());
    dispatch(quotaReset());
    dispatch(fetch(subscriptionId, claims));
  },
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Instance));
