import * as constants from './constants';

export const toggleEnable = () => dispatch => {
  dispatch({type: constants.TOGGLE_ENABLED});
};

export const updateAmount = payload => ({
  type: constants.UPDATE_AMOUNT,
  payload,
});

export const toggleInfinite = () => ({
  type: constants.TOGGLE_INFINITE,
});

export const receiveSubscriptionDetails = details => ({
  type: constants.RECEIVE_SUBSCRIPTION_DETAILS,
  payload: details,
});

export const updateInterval = (payload, error) => ({
  type: constants.UPDATE_INTERVAL,
  payload,
  error,
});

export const updateOverage = payload => ({
  type: constants.UPDATE_QUOTA_OVERAGE,
  payload,
});

export const updateStyle = (payload, error) => ({
  type: constants.UPDATE_QUOTA_STYLE,
  payload,
  error,
});

export const receiveQuotaUsage = payload => ({
  type: constants.UPDATE_QUOTA_USAGE,
  payload,
});

export const receiveV3Policies = payload => ({
  type: constants.UPDATE_V3_POLICIES,
  payload,
});

export const reset = () => ({type: constants.RESET});
